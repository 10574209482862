<template>
  <div
    class="flex flex-col border-2 border-gray-300 rounded p-3 justify-between text-left"
  >
    <div class="font-bold flex justify-left items-center gap-2 mb-1">
        <img :src="infoIcon" class="w-5 h-5" alt="info icon" />
        {{ this.$t('transitSummary.summary') }}
    </div>
    <p>{{ this.$t('transitSummary.wagons') }}: {{ transit_summary.wagons }}</p>
    <p>{{ this.$t('transitSummary.bic') }}: {{ transit_summary.containers }}</p>
    <p>{{ this.$t('transitSummary.adr') }}: {{ transit_summary.adr }}</p>
    <p v-if="transit_summary.direction && transit_summary.direction !== 'NONE'">
      {{ this.$t('transitSummary.direction') }}: {{ this.$t(`transitSummary.${transit_summary.direction}`) }}
    </p>
  </div>
</template>

<script>
import infoIcon from '@/assets/img/info.png';

export default {
  name: 'TransitSummary',
  props: {
    transit_summary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      infoIcon,
    };
  },
};
</script>
