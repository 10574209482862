<template>
  <MultiSelectCombo ref="multiSelectCombo" :buttonText="this.$t('locationFilter.location')" :uniqueComboName="this.uniqueComboName"
    :comboOptions="this.locationsArray" leftButtonIconName="locationIcon" signalOnChange="update:filterByLocation" />
</template>

<script>
import MultiSelectCombo from './generic/MultiSelectCombo.vue';

export default {
  name: 'LocationFilter',
  components: {
    MultiSelectCombo,
  },
  data() {
    return {
      locationsArray: [],
    };
  },
  props: {
    uniqueComboName: {
      type: String,
      required: true,
    },
  },
  methods: {
    computeLocationsArray(locations) {
      this.locationsArray = [];
      Object.values(locations).forEach((location) => {
        this.locationsArray.push({ id: location.id, value: location.name });
      });
    },
    selectAllOptions() {
      this.$refs.multiSelectCombo.selectAllOptions();
    },
  },
  mounted() {
    this.emitter.on('locations-updated', (locations) => {
      this.computeLocationsArray(locations);
    });
  },
  unmounted() {
    this.emitter.off('locations-updated');
  },
};
</script>
