<template>
  <div class="flex items-center space-x-4 ml-4 mt-4 relative">
    <img
      :src= this.appLogo
      class="h-12 w-auto"
      alt="AllRead Logo"/>
    <div class="absolute top-6 right-24 h-16 w-26" v-if="isSignedIn">
      <UserMenu @accountInfoFromUserMenu="accountInfoReceived" />
    </div>
  </div>
  <div class="absolute w-full">
  <ToastComponent ref="userAccountToast" class="flex mx-auto relative -top-3 z-10 justify-center items-center"/>
  </div>
  <div class="flex items-center justify-center">
    <AccountInfo />
  </div>
</template>

<script>
import ToastComponent from '@/components/generic/ToastComponent.vue';
import AccountInfo from '@/components/AccountInfo.vue';
import useTransitStore from '@/stores/TransitStore';
import userManagementStore from '@/stores/UserManagementStore';
import UserMenu from '@/components/UserMenu.vue';
import { thereIsClientLogo } from '@/../TransitViewerConfig';
import { getIcon } from '@/utils/icons';

export default {
  name: 'UserView',
  setup() {
    const transitStore = useTransitStore();

    return {
      transitStore,
    };
  },
  data() {
    return {
      appLogo: getIcon('applicationLogo'),
    };
  },
  components: {
    AccountInfo,
    UserMenu,
    ToastComponent,
  },
  methods: {
    accountInfoReceived() {
      this.$router.push({ path: '/user' });
    },
  },
  computed: {
    isSignedIn() {
      return userManagementStore().isSignedIn();
    },
  },
  async mounted() {
    if (thereIsClientLogo) {
      const clientLogo = await this.transitStore.fetchClientLogo();
      if (clientLogo) {
        this.appLogo = clientLogo;
      }
    }
    this.emitter.on('show-toast', (toastInfo) => {
      this.$refs.userAccountToast.openToast(toastInfo);
    });
    try {
      this.isLoading = true;
      await this.transitStore.fetchFacility();
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  },
  unmounted() {
    this.emitter.off('show-toast');
  },
};
</script>
