<template>
  <div v-if="show" class="fixed inset-0 z-20 flex items-center justify-center bg-[rgba(255,255,255,0.6)]">
    <img :src="loadingIcon" class="w-32 h-32" alt="Loading data gif"/>
  </div>
</template>

<script>
import { getIcon } from '@/utils/icons';

export default {
  props: ['show'],
  name: 'LoadingOverlay',
  data() {
    return {
      loadingIcon: getIcon('loadingGif'),
    };
  },
};
</script>
