<template>
  <div>
  </div>
</template>

<script>
import { transitStorageWs } from '@/../TransitViewerConfig';

export default {
  name: 'WebSocketConnector',
  data() {
    return {
      socket: null,
      reconnectTimerId: -1,
      connected: false,
      error: false,
    };
  },
  methods: {
    connectWS() {
      if (this.socket) this.socket.close();

      this.socket = new WebSocket(transitStorageWs());
      if (this.socket) {
        this.socket.onopen = () => {
          this.reconnectTimerId = -1;
          this.error = false;
          this.connected = true;
        };
        this.socket.onmessage = (event) => {
          const parsedData = JSON.parse(event.data);
          if (parsedData.type === 'close_transit') {
            this.$emit('event-new-transit', parsedData.transit_id);
          }
        };
        this.socket.onerror = (event) => {
          console.error('Error from WebSocket server:', event);
          this.error = true;
        };
        this.socket.onclose = () => {
          this.connected = false;
          if (this.error) {
            this.error = false;
            this.reconnectTimerId = setTimeout(this.connectWS, 10000);
          }
        };
      }
    },
  },
  mounted() {
    this.connectWS();
  },
  unmounted() {
    this.error = false;
    if (this.socket) {
      clearTimeout(this.reconnectTimerId);
      this.reconnectTimerId = -1;
      this.socket.close();
      this.socket = null;
    }
  },
};
</script>
