<template>
  <div class='relative w-full bg-[#1e3c53] rounded-xl shadow px-3 mb-5'
  @keydown.esc="showFilter = false">
    <WebSocketConnector @event-new-transit="newTransit"/>
    <div class="flex grid grid-cols-3 py-3">
      <div class="flex col-start-2 place-content-center gap-3">
        <LocationFilter
          uniqueComboName="liveLocationCombo"
          @update:filterByLocation="handleFilterByLocationChange"/>
        <SearchBar
          uniqueSearchBarName='liveTabSearchBar'
          :currentFetchParams='currentFetchParams'
          @update-fetch-params="updateFetchParams"/>
      </div>
    </div>
    <hr class="border-t border-sky-300 mb-2"/>
    <div class="pb-3">
      <LoadingOverlay v-if="isLoading" :show="isLoading" />
      <div v-else-if="transitStore.errors.fetchTransits"
      class="p-4 my-4 border rounded border-red-500 text-red-500">
      {{ transitStore.errors.fetchTransits }}
      </div>
      <div v-else-if="transitStore.transit.isNoContent"
        class="p-4 mt-4 border rounded bg-sky-100 border-sky-500 text-sky-800">
        <p class="text-2xl font-bold">{{ $t('transitHistoryTab.noTransitsToShow') }}</p>
      </div>
      <div v-else class="flex flex-wrap justify-center">
      <TransitCard
          v-for="transit in transitStore.transit.transits"
          :key="transit.id"
          :transit="transit"
          :class="this.animateTransitId === transit.id ? 'animate-newtransit' : ''"
      />
      </div>
    </div>
    <PopupImage
        :show="showPopup"
        :section="selectedSection"
        @close="showPopup = false"
      />
  </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import { getIcon } from '@/utils/icons';

import PopupImage from './PopUpImage.vue';
import TransitCard from './TransitCard.vue';
import SearchBar from './SearchBar.vue';
import WebSocketConnector from './WebSocketConnector.vue';
import LocationFilter from './LocationFilter.vue';
import LoadingOverlay from './LoadingOverlay.vue';

export default {
  name: 'TransitLiveTab',
  components: {
    LocationFilter,
    TransitCard,
    SearchBar,
    WebSocketConnector,
    PopupImage,
    LoadingOverlay,
  },
  data() {
    return {
      elementsPerPage: 50,
      currentPage: 1,
      totalItems: 0,
      showFilter: false,
      currentFetchParams: {
        sortingType: 'desc',
        sortAttribute: 'end_time',
        initialTime: 0,
        finishTime: Math.floor(Date.now() / 1000),
        searchValue: '',
        transitType: [''],
        pageNumber: 0,
        pageSize: 50,
      },
      isLoading: true,
      animateTransitId: 0,
      showPopup: false,
      selectedSection: {},
    };
  },
  setup() {
    const transitStore = useTransitStore();
    const getFilterIcon = getIcon;
    return {
      transitStore,
      getFilterIcon,
    };
  },
  methods: {
    async updateFetchParams(params) {
      this.currentFetchParams = params;
      try {
        await this.transitStore.fetchTransits(this.currentFetchParams);
        this.totalItems = this.transitStore?.transit?.count ?? 0;
      } catch (err) {
        console.error(err);
      }
    },
    handleFilterByLocationChange(locations) {
      this.currentFetchParams.locations = locations;
      this.refreshTable();
    },
    refreshTable() {
      this.currentFetchParams.finishTime = Math.floor(Date.now() / 1000);
      this.updateFetchParams(this.currentFetchParams);
    },
    newTransit(transitId) {
      this.animateTransitId = transitId;
      this.refreshTable();
    },
    async openPopupEvent(section) {
      this.selectedSection = section;
      this.showPopup = true;
    },
    restoreSessionFetchParams() {
      if (sessionStorage.liveLocationCombo) {
        this.currentFetchParams.locations = Object(JSON.parse(sessionStorage.liveLocationCombo));
      }
      if (sessionStorage.liveTabSearchBar) {
        this.currentFetchParams.searchValue = JSON.parse(sessionStorage.liveTabSearchBar);
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.transitStore.fetchLocation();
      this.emitter.emit('locations-updated', this.transitStore.location);
      this.restoreSessionFetchParams();
      await this.transitStore.fetchTransits(this.currentFetchParams);
      this.totalItems = this.transitStore?.transit?.count ?? 0;
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {
    this.emitter.on('click-on-forensic', this.openPopupEvent);
  },
  unmounted() {
    this.emitter.off('click-on-forensic');
  },
};
</script>
