<template>
  <div v-if="show" class="fixed inset-0 z-20 flex items-center justify-center bg-[rgba(0,0,0,0.5)]"
    @click="closePopup()"
    @keyup.esc="closePopup()"
    tabindex="0">
    <ToastComponent ref="toastOnPopUp" class="mx-auto"/>
    <div class="bg-gray-200 relative p-5 w-4/5 flex flex-col max-h-screen overflow-auto rounded-xl"
      @click.stop>
      <button @click="closePopup()"
      class="absolute top-3 right-3"
      :title="this.$t('popUpImage.closePopup')">
        <svg width="36px" height="36px" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg" class="bg-white rounded-full hover:bg-gray-200">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2
            12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"></path>
            <path d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12
            10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232
            9.26258 15.3232 9.73746 15.0303 10.0304L13.0607 12L15.0303 13.9696C15.3232
            14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696
            15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969
            15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967
            10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z" fill="#000000"></path>
          </g>
        </svg>
      </button>
      <div class="flex flex-row gap-3 rounded-xl">
        <div class="w-full object-contain h-full relative self-center justify-center align-middle">
          <div v-if="!isFetchingImage">
            <vue-photo-zoom-pro
              v-if="thereAreImages() && initialize()"
              :high-url="this.selectedImage"
              :url="this.selectedImage"
              @mouseover="hover = true"
              @focusin="hover = true"
              @mouseleave="hover = false"
              @focusout="hover = false"
              class="w-full object-contain h-full relative">
              <div v-if="roiStyle" :style="roiStyle" class="border-2 border-green-500 absolute"></div>
              <div
                v-bind:class="{'opacity-0': hover, 'opacity-100': !hover}"
                class="absolute top-0 left-1/2 transform -translate-x-1/2 pt-2
                  transition ease-in-out duration-500">
                <div class="bg-gray-800 bg-opacity-70 text-white text-center rounded px-2 py-1 text-xl">
                  {{ this.$t('popUpImage.hoverToZoom') }}
                </div>
              </div>
            </vue-photo-zoom-pro>
            <div v-else class="basis-[80%] p-4 mt-4 border rounded bg-red-50 border-red-200 text-red-800">
              <p class="text-2xl font-bold">
                {{ this.$t('popUpImage.couldNotRetrieveTheImage') }}
              </p>
            </div>
          </div>
          <div v-else class="inline-flex align-middle justify-center
          p-4 border rounded-xl bg-sky-100 border-sky-500 text-sky-800 w-fit">
            <p class="text-2xl font-bold">
              {{ this.$t('popUpImage.loadingImage') }}
            </p>
          </div>
        </div>
        <div class="basis-[20%] bg-white rounded-lg pt-5 px-3 flex flex-col gap-3">
          <CardSection
            class=""
            :section="section"
            :showImageButton=false
            :borderless="true"
            :sectionIndex=0
            @update-value="updateSectionValue"
          />
          <hr class="border-t-2 border-gray-300 my-2"/>
          <div class="grid grid-cols-3 cursor-default text-sm text-left w-auto gap-1 text-ellipsis overflow-hidden">
            <div class="">
              {{ this.$t('popUpImage.criteria') }}
            </div>
            <div class="col-span-2">
              <b class="break-keep text-center capitalize">
                {{ this.$t(`popUpImage.${this.selectedImageData.criteria}`) }}
              </b>
            </div>
            <div class="">
              {{ this.$t('popUpImage.from') }}
            </div>
            <div class="col-span-2 capitalize">
              <b>{{ this.selectedImageData.stream_name.replace(/_(\w+)_/g, ' $1 ').replace(/_/g, ' ').replace(/[0-9]/g, '') }}</b>
            </div>
            <div class="">
              {{ this.$t('popUpImage.at') }}
            </div>
            <div class="col-span-2"><b>{{ getDateInLocalTime() }}</b></div>
          </div>
          <hr class="border-t-2 border-gray-300 mt-2"/>
          <div class="text-left bg-gray-200 rounded-xl max-h-[425px] overflow-auto">
            <div class="text-sm bg-gray-300 rounded-t-xl font-semibold border-b-2 border-gray-400 p-2 pt-3">
              {{ this.$t('popUpImage.imagesByCriteria') }}
            </div>
            <div v-for="(image, index) in this.section.images" :key="image.criteria">
              <button
                :class="['capitalize p-1 w-full text-left text-xs',
                        (index % 2 === 0) ? 'bg-gray-200' : 'bg-gray-100',
                        (this.selectedImageData === image) ? 'bg-sky-200 font-bold text-sky-900' : 'hover:bg-sky-100']"
                @click="changeSelectedImage(image)">
                <p>{{ this.$t(`popUpImage.${this.selectedImageData.criteria}`) }} - {{ this.$t(image.detectionTypeName) }}</p>
                <p class="text-[10px] italic">({{ image.stream_name.replace(/_(\w+)_/g, ' $1 ').replace(/_/g, ' ').replace(/[0-9]/g, '') }})</p>
              </button>
              <div v-for="additionalImage in image.additionalStreamImages" :key="additionalImage.criteria">
                <button
                  :class="['flex capitalize p-1 w-full text-left text-xs pl-4',
                        (index % 2 === 0) ? 'bg-gray-200' : 'bg-gray-100',
                        'border-t border-dotted border-gray-400',
                        (this.selectedImageData === additionalImage) ? 'bg-sky-200 font-bold text-sky-900' : 'hover:bg-sky-100']"
                  @click="changeSelectedImage(additionalImage)">
                  <svg width="12px" height="12px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                  fill="#000000" transform="matrix(-1, 0, 0, 1, 0, 0)" alt="Additional stream">
                    <g id="SVGRepo_iconCarrier">
                      <path fill="none" stroke="#000000" stroke-width="2" d="M9,4 L4,9 L9,14 M18,19 L18,9 L5,9" transform="matrix(1 0 0 -1 0 23)">
                      </path>
                    </g>
                  </svg>
                  <p class="text-[10px] italic">
                    ({{ additionalImage.stream_name.replace(/_(\w+)_/g, ' $1 ').replace(/_/g, ' ').replace(/[0-9]/g, '') }})
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuePhotoZoomPro from 'vue-photo-zoom-pro';
import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css';

import useTransitStore from '@/stores/TransitStore';
import closeButton from '@/assets/img/close-button.png';
import { getStatisticalTypeName } from '@/utils/lookup';
import ToastComponent from './generic/ToastComponent.vue';
import CardSection from './CardSection.vue';

export default {
  props: ['show', 'section'],
  name: 'PopupImage',
  components: {
    CardSection,
    vuePhotoZoomPro,
    ToastComponent,
  },
  setup() {
    const getStatsTypeName = getStatisticalTypeName;
    return {
      transitStore: useTransitStore(),
      getStatsTypeName,
    };
  },
  data() {
    return {
      closeButton,
      initialized: false,
      isFetchingImage: false,
      selectedImage: '',
      selectedImageData: {},
      hover: false,
    };
  },
  computed: {
    roiStyle() {
      if (!this.selectedImageData?.roi) return null;

      const imageHeight = this.selectedImageData.height;
      const imageWidth = this.selectedImageData.width;

      const topPercent = (this.selectedImageData.roi.y / imageHeight) * 100;
      const leftPercent = (this.selectedImageData.roi.x / imageWidth) * 100;
      const widthPercent = (this.selectedImageData.roi.width / imageWidth) * 100;
      const heightPercent = (this.selectedImageData.roi.height / (imageHeight)) * 100;

      return {
        top: `${topPercent}%`,
        left: `${leftPercent}%`,
        width: `${widthPercent}%`,
        height: `${heightPercent}%`,
      };
    },
  },
  methods: {
    initialize() {
      if (!this.initialized) {
        // eslint-disable-next-line
        this.selectedImageData = this.section.images[0];
        this.changeSelectedImage(this.selectedImageData);
        this.initialized = true;
      }
      return true;
    },
    thereAreImages() {
      return (this.section?.images?.length > 0);
    },
    getDateInLocalTime() {
      const dateObject = new Date(this.selectedImageData.time);
      return new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'medium' }).format(dateObject);
    },
    // eslint-disable-next-line no-unused-vars
    async updateSectionValue({ index, updatedValue, sectionIndex }) {
      const currentSection = this.section.values[index];
      if (currentSection.value === updatedValue) return;
      let putResult = false;
      if (currentSection.value_type === 'reading') {
        putResult = await this.transitStore.putReadingValue({ readingId: currentSection.db_id, newValue: updatedValue });
      } else {
        putResult = await this.transitStore.putClassificationValue({ classificationId: currentSection.db_id, newValue: updatedValue });
      }
      if (putResult) {
        currentSection.edited = true;
        currentSection.value = updatedValue;
        this.$refs.toastOnPopUp.openToast({ toastType: 'info', toastText: 'Successfully saved' });
      } else {
        const replaceValue = currentSection.value;
        currentSection.value = replaceValue;
        this.$refs.toastOnPopUp.openToast({ toastType: 'error', toastText: 'Something gone wrong while saving edition' });
      }
    },
    async changeSelectedImage(newSelectedImageData) {
      this.isFetchingImage = true;
      this.selectedImage = '';
      this.selectedImageData = newSelectedImageData;
      try {
        this.selectedImage = await this.transitStore.fetchImage(
          this.selectedImageData?.stream_name,
          this.selectedImageData?.id,
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isFetchingImage = false;
      }
    },
    closePopup() {
      this.initialized = false;
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
::v-deep .selector {
  border: 5px #00a1e2 solid;
  border-radius: 10px;
}
</style>
