<template>
    <Menu as="div" class="relative inline-block text-left z-20" :key="this.userName">
        <div>
            <MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-full
                bg-white px-3 py-2 hover:bg-gray-100" id="user-menu-button" aria-expanded="true" aria-haspopup="true"
                :title="this.userName">
                <div class="flex text-center items-center gap-2">
                  <svg height="28" width="28" fill="#1e3c53" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path d="M10.31,9.12H5.5A4.52,4.52,0,0,0,1,13.62,2.34,2.34,0,0,0,1,14H14.78a2.34,2.34,0,0,0,
                      0-.38A4.51,4.51,0,0,0,10.31,9.12ZM8,7.88A3.94,3.94,0,1,0,4.06,3.94,3.94,3.94,0,0,0,8,7.88Z">
                      </path>
                    </g>
                  </svg>
                  <p>{{ this.userName }}</p>
                </div>
            </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md
                bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                    <MenuItem v-slot="{ active }">
                        <button type="button" :class="[active ? 'bg-gray-100 text-gray-900' :
                        'text-gray-700', 'block w-full px-4 py-2 text-left text-sm']"
                        @click="handleAccountInfo();">{{ this.$t('userMenu.accountInfo') }}</button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <button type="button" :class="[active ? 'bg-gray-100 text-gray-900' :
                        'text-gray-700', 'block w-full px-4 py-2 text-left text-sm']"
                        @click="handleSignOut();">{{ this.$t('userMenu.signOut') }}</button>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';

import useTransitStore from '@/stores/TransitStore';
import userManagementStore from '@/stores/UserManagementStore';

export default {
  name: 'LoginPage',
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  setup() {
    const transitStore = useTransitStore();
    return {
      transitStore,
      userManagement: userManagementStore(),
    };
  },
  data() {
    return {
      userName: '',
    };
  },
  methods: {
    handleSignOut() {
      userManagementStore().signOut();
      this.$router.push({ path: '/login' });
    },
    async updateUserName() {
      this.userName = this.userManagement.getUserName();
      if (this.userName === '') {
        this.userName = this.userManagement.getEmail();
      }
    },
    async handleAccountInfo() {
      const accountInfo = await this.transitStore.fetchAccountInfo();
      if (accountInfo !== undefined && accountInfo.email !== undefined) {
        this.$emit('accountInfoFromUserMenu', accountInfo);
      }
    },
  },
  async mounted() {
    this.emitter.on('update-user-account-info', this.updateUserName);
    this.userName = this.userManagement.getUserName();
  },
  unmounted() {
    this.emitter.off('update-user-account-info');
  },
};
</script>
