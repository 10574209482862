<template>
  <div class="flex gap-1">
    <label for="search">
      <input
        id="search"
        ref="searchInput"
        type="text"
        v-model="search"
        class="search-input p-1 rounded border border-gray-300 w-[200px] pl-10"
        autocomplete="off"
        v-on:keyup.enter="applyFilter"
      />
    </label>
    <button @click="applyFilter"
        class="ml-1 px-4 py-1 bg-sky-500 text-white rounded
            hover:bg-sky-700 transition-colors duration-300"
        :title="$t('searchBar.search')">
        {{ $t('searchBar.search') }}
    </button>
  </div>
</template>

<style scoped>
.search-input {
  background-image: url('@/assets/img/magnifier.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px 20px;
}
</style>

<script>
export default {
  name: 'SearchBar',
  props: {
    currentFetchParams: {
      type: Object,
      required: true,
    },
    uniqueSearchBarName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    applyFilter() {
      const updatedParams = { ...this.currentFetchParams };
      updatedParams.searchValue = this.search;
      sessionStorage.setItem(this.uniqueSearchBarName, JSON.stringify(this.search));
      this.$emit('update-fetch-params', updatedParams);
    },
  },
  mounted() {
    const searchBarName = sessionStorage.getItem(this.uniqueSearchBarName);
    if (searchBarName) {
      this.search = JSON.parse(searchBarName);
    }
  },
};
</script>
