<template>
  <div class="relative bg-gray-100 m-3 p-4 flex items-center mx-auto gap-4 rounded-2xl">
    <CardLabel :cardLabelText="this.$t(getStatTypeName(element.type))" :bold="true"/>
    <img :src="this.getStatsTypeIcon()" alt="Statistical Type Icon" class="inline-block mr-6 ml-3 h-12 w-12">
    <div class="flex-grow flex-shrink-0">
      <div><strong>{{ this.$t('statsCard.start') }}</strong> {{ getDateInLocalTimeFormat(element?.start_time) ?? 0 }}</div>
      <div><strong>{{ this.$t('statsCard.end') }}</strong> {{ getDateInLocalTimeFormat(element?.end_time) ?? 0 }}</div>
    </div>
    <div
      v-for="(value, key) in filteredElementData"
      :key="key"
      class="relative bg-gray-100 p-2 border border-gray-300 rounded flex-grow flex-shrink-0 min-w-1/4">
      <div
      :class="['absolute top-[-0.5rem] left-2 bg-white px-2 rounded-t text-gray-700 capitalize',
        this.$t(`statsCard.${key}`).length > 18 ? 'text-xs' : 'text-base']">
        {{ this.$t(`statsCard.${key}`) }}
      </div>
      <div class="py-4 px-16" v-if="key === 'average_confidence'">{{ confidenceRounded(value, 2) }}</div>
      <div class="py-4 px-16" v-else>{{ value }}</div>
    </div>
  </div>
</template>

<script>

import { getStatisticalTypeIconName, getStatisticalTypeName } from '@/utils/lookup';
import { getIcon } from '@/utils/icons';
import valueToPercent from '@/utils/math';
import { getDateInLocalTime } from '@/utils/date';

import CardLabel from './CardLabel.vue';

export default {
  name: 'StatsCard',
  components: {
    CardLabel,
  },
  setup() {
    const confidenceRounded = valueToPercent;
    const getDateInLocalTimeFormat = getDateInLocalTime;
    const getStatTypeName = getStatisticalTypeName;
    return {
      confidenceRounded,
      getDateInLocalTimeFormat,
      getStatTypeName,
    };
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filteredElementData() {
      const filteredData = { ...this.element };
      delete filteredData.type;
      delete filteredData.start_time;
      delete filteredData.end_time;
      return filteredData;
    },
  },
  methods: {
    getStatsTypeIcon() {
      return getIcon(getStatisticalTypeIconName(this.element.type));
    },
  },
};
</script>
