export const TRUCK_TYPES = [
  'truck loaded',
  'truck unloaded',
  'truck ambiguous',
  'truck container',
  'truck empty',
  'truck others',
  'truck box',
  'tractor',
  'truck bulk',
  'truck tanker',
  'truck container tanker',
  'truck car carrier',
  'truck car carrier empty',
];
export const CAR_TYPES = ['car', 'van'];
export const VEHICLE_INFO_TYPES = [
  'vehicleinformation_brand',
  'vehicleinformation_model',
  'vehicleinformation_color',
];
