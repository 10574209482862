import greenTick from '@/assets/img/green-tick.png';
import amberTick from '@/assets/img/amber-tick.png';
import redTick from '@/assets/img/red-tick.png';
import truckIcon from '@/assets/img/truck.png';
import carIcon from '@/assets/img/car.png';
import motoIcon from '@/assets/img/moto.png';
import containerIcon from '@/assets/img/container.png';
import licensePlateIcon from '@/assets/img/license-plate.png';
import trailerLicensePlateIcon from '@/assets/img/trailer-license-plate.png';
import brandIcon from '@/assets/img/brand.png';
import adrIcon from '@/assets/img/adr.png';
import wagonIcon from '@/assets/img/wagon.png';
import locomotiveIcon from '@/assets/img/locomotive.png';
import questionMarkIcon from '@/assets/img/question-mark.png';
import pictureIcon from '@/assets/img/picture.png';
import checkboxChecked from '@/assets/img/checkbox_checked.png';
import checkboxUnchecked from '@/assets/img/checkbox_unchecked.png';
import roadIcon from '@/assets/img/road.png';
import craneIcon from '@/assets/img/crane.png';
import trainIcon from '@/assets/img/train.png';
import filterIcon from '@/assets/img/filter.png';
import locationIcon from '@/assets/img/location.png';
import transitIcon from '@/assets/img/transit.png';
import vehicleClassificationIcon from '@/assets/img/vehicle-classification.png';
import closeButtonIcon from '@/assets/img/close-button.png';
import flagIcon from '@/assets/img/flag.png';
import infoIcon from '@/assets/img/info.png';
import warnIcon from '@/assets/img/warn.png';
import errorIcon from '@/assets/img/error.png';
import alertIcon from '@/assets/img/alert.png';
import csvReportIcon from '@/assets/img/report_csv.png';
import xlsxReportIcon from '@/assets/img/report_xlsx.png';
import loggedInIcon from '@/assets/img/loggedIn.png';
import loadingGif from '@/assets/gif/waiting.gif';
import applicationLogo from '@/assets/img/logo.png';

import { TRUCK_TYPES, CAR_TYPES, VEHICLE_INFO_TYPES } from './types';

export function getIconName(iconType, classificationValue = null) {
  if (iconType === 'vehicleclassification'
    || iconType === 'vehicledetection') {
    const noUnderscoreValue = classificationValue.replaceAll('_', ' ');
    if (TRUCK_TYPES.includes(noUnderscoreValue)) return 'truckIcon';
    if (CAR_TYPES.includes(noUnderscoreValue)) return 'carIcon';
    if (noUnderscoreValue === 'motorcycle') return 'motoIcon';
  }
  if (VEHICLE_INFO_TYPES.includes(iconType)) return 'brandIcon';
  if (iconType === 'license plate') return 'licensePlateIcon';
  if (iconType === 'trailer license plate') return 'trailerLicensePlateIcon';
  if (iconType === 'BIC') return 'containerIcon';
  if (iconType === 'adr') return 'adrIcon';
  if (iconType === 'universalwagon') return 'wagonIcon';
  if (iconType === 'road') return 'roadIcon';
  if (iconType === 'train') return 'trainIcon';
  if (iconType === 'crane') return 'craneIcon';
  return 'questionMark';
}

export function getIcon(iconName) {
  switch (iconName) {
    case 'truckIcon':
      return truckIcon;
    case 'carIcon':
      return carIcon;
    case 'motoIcon':
      return motoIcon;
    case 'containerIcon':
      return containerIcon;
    case 'licensePlateIcon':
      return licensePlateIcon;
    case 'trailerLicensePlateIcon':
      return trailerLicensePlateIcon;
    case 'brandIcon':
      return brandIcon;
    case 'adrIcon':
      return adrIcon;
    case 'wagonIcon':
      return wagonIcon;
    case 'locomotiveIcon':
      return locomotiveIcon;
    case 'pictureIcon':
      return pictureIcon;
    case 'checkboxChecked':
      return checkboxChecked;
    case 'checkboxUnchecked':
      return checkboxUnchecked;
    case 'roadIcon':
      return roadIcon;
    case 'craneIcon':
      return craneIcon;
    case 'trainIcon':
      return trainIcon;
    case 'filterIcon':
      return filterIcon;
    case 'locationIcon':
      return locationIcon;
    case 'transitIcon':
      return transitIcon;
    case 'vehicleClassificationIcon':
      return vehicleClassificationIcon;
    case 'closeButtonIcon':
      return closeButtonIcon;
    case 'flagIcon':
      return flagIcon;
    case 'infoIcon':
      return infoIcon;
    case 'warnIcon':
      return warnIcon;
    case 'errorIcon':
      return errorIcon;
    case 'greenTick':
      return greenTick;
    case 'amberTick':
      return amberTick;
    case 'redTick':
      return redTick;
    case 'alertIcon':
      return alertIcon;
    case 'csvReportIcon':
      return csvReportIcon;
    case 'xlsxReportIcon':
      return xlsxReportIcon;
    case 'loggedInIcon':
      return loggedInIcon;
    case 'loadingGif':
      return loadingGif;
    case 'applicationLogo':
      return applicationLogo;
    default:
      return questionMarkIcon;
  }
}

export function getConfidenceIcon(confidenceValue) {
  const confidence = confidenceValue ?? 0;
  if (confidence >= 0.85) {
    return getIcon('greenTick');
  } if (confidence >= 0.6) {
    return getIcon('amberTick');
  }
  return getIcon('redTick');
}
