import { defineStore } from 'pinia';

const userManagementStore = defineStore('userManagementStore', {
  state: () => ({
    userManagement: {
      privateSessionInfo: {},
      privateAccountInfo: {},
    },
  }),
  actions: {
    setSignInInfo(signInInfo) {
      this.userManagement.privateSessionInfo.token = signInInfo.token;
      this.userManagement.privateAccountInfo.id = signInInfo.user?.id;
      this.userManagement.privateAccountInfo.name = signInInfo.user?.name;
      this.userManagement.privateAccountInfo.surname = signInInfo.user?.surname;
      this.userManagement.privateAccountInfo.email = signInInfo.user?.email;
      sessionStorage.userManagement = JSON.stringify(this.userManagement);
    },
    getUserToken() {
      return this.userManagement.privateSessionInfo?.token;
    },
    getUserId() {
      return this.userManagement.privateAccountInfo.id;
    },
    getUserName() {
      return this.userManagement.privateAccountInfo.name;
    },
    getUserSurname() {
      return this.userManagement.privateAccountInfo.surname;
    },
    getEmail() {
      return this.userManagement.privateAccountInfo.email;
    },
    signOut() {
      this.userManagement.privateSessionInfo = {};
      this.userManagement.privateAccountInfo = {};
      sessionStorage.userManagement = JSON.stringify(this.userManagement);
    },
    isSignedIn() {
      if (Object.keys(this.userManagement.privateSessionInfo).length === 0
      && sessionStorage.userManagement) {
        this.userManagement = JSON.parse(sessionStorage.userManagement);
      }

      return this.userManagement.privateSessionInfo.token !== undefined
        && this.userManagement.privateAccountInfo.id !== undefined
        && !Number.isNaN(this.userManagement.privateAccountInfo.id);
    },
    setAccountInfo(accountInfo) {
      this.userManagement.privateAccountInfo = {
        id: accountInfo.id,
        email: accountInfo.email,
        name: accountInfo.name,
        surname: accountInfo.surname,
      };
      sessionStorage.userManagement = JSON.stringify(this.userManagement);
    },
    getAccountInfo() {
      return this.userManagement.privateAccountInfo;
    },
  },
});

export default userManagementStore;
