<template>
  <LoginPage />
</template>

<script>
import LoginPage from '@/components/LoginPage.vue';

export default {
  name: 'AuthView',
  components: {
    LoginPage,
  },
};
</script>
