<template>
  <div v-if="showAccountInfoId !== undefined" class="flex w-1/4 mt-20 items-start shadow-2xl
  bg-white border border-gray-200 rounded-lg shadow hover:border-gray-400 justify-center py-4 gap-10 px-3">
    <Form class="space-y-6" action="#" v-slot="{ errors }"
         method="POST" @submit="submitForm"
          :validation-schema="schemaValidation">
        <div class="flex flex-col rounded-md space-y-2 gap-3">
          <div>
            <label htmlFor="email">
              <span class="font-medium">{{ $t('accountInfo.emailAdress') }}</span>
              <Field :validateOnBlur="true"
                :validateOnChange="false" :validateOnInput="true"
                id="email" name="email" v-model="this.accountInfo.email"
                  type="text" required autocomplete="email"
                  :class="fieldStyle" :placeholder="$t('accountInfo.emailAdress')" />
            </label>
            <ErrorMessage name="email" class="invalid-feedback" />
          </div>
          <div>
            <label htmlFor="name">
              <span class="font-medium">{{ $t('accountInfo.name') }}</span>
              <Field :validateOnBlur="true"
                :validateOnChange="false" :validateOnInput="true"
                id="name" name="name" v-model="this.accountInfo.name"
                  type="text" required autocomplete="name"
                  :class="fieldStyle" :placeholder="$t('accountInfo.name')" />
            </label>
            <ErrorMessage name="name" class="invalid-feedback" />
          </div>
          <div>
            <label htmlFor="surname">
              <span class="font-medium">{{ $t('accountInfo.surname') }}</span>
              <Field :validateOnBlur="true"
                :validateOnChange="false" :validateOnInput="true"
                id="surname" name="surname" v-model="this.accountInfo.surname"
                  type="text" required autocomplete="surname"
                  :class="fieldStyle" :placeholder="$t('accountInfo.surname')" />
            </label>
            <ErrorMessage name="surname" class="invalid-feedback" />
          </div>
        </div>
        <div>
          <hr class="border-t-1 border-dashed border-gray-300 mt-4 mb-2"/>
          <div class="flex gap-6 justify-between">
            <button class="mt-2 py-2 text-gray-600 hover:font-bold
            underline underline-offset-4 hover:decoration-4 hover:text-rose-950"
            @click="goBack()">
              {{ $t('accountInfo.goBack') }}
            </button>
            <button @click="applyFilter" type="submit"
              class="mt-2 px-3 py-1 bg-sky-500 text-white rounded
                hover:bg-sky-700 transition-colors duration-300"
                :disabled="errors.email || errors.name">
                {{ $t('accountInfo.modify') }}
            </button>
          </div>
        </div>
    </Form>
  </div>
</template>
<script>
import {
  Form, Field, ErrorMessage,
} from 'vee-validate';
import * as yup from 'yup';
import useTransitStore from '@/stores/TransitStore';
import userManagementStore from '@/stores/UserManagementStore';

export default {
  name: 'AccountInfo',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const transitStore = useTransitStore();
    return {
      transitStore,
    };
  },
  data() {
    const fieldStyle = 'w-full p-1 hover:bg-gray-100 border border-gray-300'
    + 'placeholder-gray-500 text-gray-900 rounded-md focus:outline-none'
    + 'focus:ring-sky-500 focus:border-sky-500 focus:z-10 sm:text-sm focus:shadow-inner';
    return {
      accountInfo: {},
      fieldStyle,
      schemaValidation: yup.object({
        name: yup.string().required(this.$t('accountInfo.enterValidUsername')).min(1),
        email: yup.string().required().email(this.$t('accountInfo.enterValidEmail')),
      }),
    };
  },
  mounted() {
    this.accountInfo = { ...userManagementStore().getAccountInfo() };
  },
  methods: {
    async submitForm() {
      try {
        const response = await this.transitStore.handleUserData(
          {
            email: this.accountInfo.email,
            name: this.accountInfo.name,
            surname: this.accountInfo.surname,
          },
        );
        if (response !== undefined && response.id !== undefined) {
          this.emitter.emit('show-toast', { toastType: 'info', toastText: this.$t('accountInfo.userDataUpdated') });
          this.emitter.emit('update-user-account-info');
        } else throw new Error(`No response or id is undefined: ${response}`);
      } catch (error) {
        this.emitter.emit('show-toast', {
          toastType: 'error',
          toastText: this.$t('accountInfo.errorOnUpdate'),
        });
        console.error(error);
      }
      return 1;
    },
    goBack() {
      this.$router.push({ path: '/' });
    },
  },
  computed: {
    showAccountInfoId() {
      return this.accountInfo.id;
    },
  },
};
</script>
