import { apiUrl } from '@/../TransitViewerConfig';
import userManagementStore from '@/stores/UserManagementStore';

function wait(delay) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function fetchRetry(url, delay, attempts, fetchOptions = {}) {
  let triesLeft = attempts;
  function onError(err) {
    triesLeft = attempts - 1;
    if (!triesLeft) {
      throw err;
    }
    return wait(delay).then(() => fetchRetry(url, delay, triesLeft, fetchOptions));
  }
  return fetch(url, fetchOptions).catch(onError);
}

export async function fetchFromUrl(endpoint, options = {}) {
  const defaultOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${userManagementStore().getUserToken()}`,
    },
  };
  const requestOptions = { ...defaultOptions, ...options };

  const response = await fetchRetry(`${endpoint}`, 1000, 3, requestOptions);

  if (response.status === 401) {
    console.error('Unauthorized. Expired token?');
    window.dispatchEvent(new Event('user-token-expired'));
    return response.json();
  }

  if (!response.ok && response.status !== 204) {
    throw new Error(`Error fetching data from ${endpoint}: ${response.statusText}`);
  }

  if (options.responseType === 'blob') {
    return response.blob();
  }

  if (response.status === 204) {
    return { isNoContent: true };
  }
  return response.json();
}

export async function fetchFromAPI(endpoint, options = {}) {
  return fetchFromUrl(`${apiUrl()}/api${endpoint}`, options);
}

export async function putToAPI(endpoint, options = {}) {
  const defaultOptions = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userManagementStore().getUserToken()}`,
    },
  };
  const requestOptions = { ...defaultOptions, ...options };
  let response = { ok: false };
  try {
    response = await fetchRetry(`${apiUrl()}/api${endpoint}`, 1000, 3, requestOptions);
  } catch (error) {
    return false;
  }

  if (!response.ok && response.status !== 204) {
    console.error('Error putting data to', endpoint, ':[', response.statusText, ']');
    return false;
  }
  return true;
}
