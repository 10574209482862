<template>
  <div :class="['flex flex-col space-y-2 justify-between',
    this.borderless ? '' : 'border border-[#1e3c53] border-opacity-30 rounded-xl p-3']">
    <div class="flex-col space-y-1">
      <div class="flex items-center justify-start space-x-4">
        <i class="min-w-max h-10">
        <img
          v-if="section?.icon"
          :src="sectionIcon(section.icon)"
          class="w-10 h-10"
          alt="section icon">
        </i>
        <div>{{ this.$t(this.getStatsTypeName(this.section.type)) }}</div>
      </div>
      <div
        v-for="(content, index) in section?.values ?? []"
        :key="content?.value"
        class="flex justify-between text-left">
        <div contenteditable="true"
          @keydown="preventReturn"
          @blur="updateValue($event, index)"
          class="flex hover:bg-gray-200 cursor-pointer rounded-md p-1 -m-1"
          spellcheck="false">
          <i v-if="content?.edited">
            <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.048"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M13 21H21" stroke="#5e5e5e" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M20.0651 7.39423L7.09967 20.4114C6.72438 20.7882 6.21446 21 5.68265 21H4.00383C3.44943 21 3 20.5466 3
                19.9922V18.2987C3 17.7696 3.20962 17.2621 3.58297 16.8873L16.5517 3.86681C19.5632 1.34721 22.5747 4.87462 20.0651
                7.39423Z" stroke="#5e5e5e" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M15.3097 5.30981L18.7274 8.72755" stroke="#5e5e5e" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </g>
            </svg>
          </i>
          {{ content?.value.replaceAll('_', ' ') ?? '' }}
        </div>
        <i class="min-w-max h-5">
          <img v-if="content.check_digit_passed" :src="confidenceIcon(content?.confidence)"
            class="w-5 h-5 self-center ml-2"
            alt="confidence icon"
            :title=confidenceRounded(content?.confidence)>
          <img v-else :src="sectionIcon('alertIcon')"
            class="w-5 h-5 self-center ml-2"
            alt="confidence icon"
            :title=content.check_digit_str>
        </i>
      </div>
    </div>
    <div v-if="showImageButton" class="flex self-center">
      <div v-if="hasSomeImage" class="bg-gray-200 rounded-xl px-2 py-1
          transition-colors duration-200 ease-in-out transform hover:bg-gray-300 hover:shadow-lg
          hover:scale-105 w-fit"
          @click="openPopup()"
          @keyup.right="openPopup()"
          tabindex="0"
          role="button">
        <i class="w-8 h-8"><img :src="sectionIcon('pictureIcon')" class="w-8 h-8" alt="camera icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getConfidenceIcon, getIcon } from '@/utils/icons';
import valueToPercent from '@/utils/math';
import { getStatisticalTypeName } from '@/utils/lookup';

export default {
  name: 'CardSection',
  props: {
    section: {
      type: Object,
      required: true,
    },
    sectionIndex: {
      type: Number,
      required: true,
      default: -1,
    },
    showImageButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    borderless: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const confidenceIcon = getConfidenceIcon;
    const confidenceRounded = valueToPercent;
    const getStatsTypeName = getStatisticalTypeName;
    const sectionIcon = getIcon;
    return {
      confidenceIcon,
      confidenceRounded,
      sectionIcon,
      getStatsTypeName,
    };
  },
  data() {
    return {
    };
  },
  computed: {
    hasSomeImage() {
      return (this.section.images?.length > 0);
    },
  },
  methods: {
    updateValue(event, index) {
      const updatedValue = event.target.innerText;
      this.$emit('update-value', { index, updatedValue, sectionIndex: this.sectionIndex });
    },
    preventReturn(event) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      if (keyCode === 13) {
        event.preventDefault();
        event.target.blur();
      }
    },
    async openPopup() {
      try {
        this.emitter.emit('click-on-forensic', this.section);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
