<template>
  <div class="flex gap-3">
    <div class="flex justify-center items-center -space-x-px">
        <button
        @click="navigate('goToFirstPage')"
        :disabled="modelValue === 1"
        :title="$t('paginationControl.firstPage')">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" viewBox="-5 -5 26 26"
        class="border cursor-pointer bg-white hover:bg-gray-100 rounded-l-lg">
          <path
          fill-rule="evenodd"
          d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5
          0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
          <path
          fill-rule="evenodd"
          d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5
          0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
        </button>
        <button
        @click="navigate('goToPreviousPage')"
        :disabled="modelValue === 1"
        :title="$t('paginationControl.previousPage')">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" viewBox="-5 -5 26 26"
        class="border cursor-pointer bg-white hover:bg-gray-100">
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
            0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
        </button>
        <div class="flex justify-center -space-x-px">
        <template v-for="(item, index) in pageNumberSequence" :key="index">
            <button v-if="item.type === 'a'"
            @click.prevent="navigate('goToPage', item.pageNumber)"
            class="px-2 border cursor-pointer bg-white hover:bg-gray-100" href="#">
            {{ item.pageNumber }}
        </button>
            <div v-else-if="item.type === 'strong'" class="px-2 border cursor-pointer font-medium bg-sky-200">
            {{ item.pageNumber }}
            </div>
        </template>
        </div>
        <button
        @click="navigate('goToNextPage')"
        :disabled="modelValue === totalPages"
        :title="$t('paginationControl.nextPage')">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" viewBox="-5 -5 26 26"
        class="border cursor-pointer bg-white hover:bg-gray-100">
          <path
            transform="rotate(180 8 8)"
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
            0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
        </button>
        <button
        @click="navigate('goToLastPage')"
        :disabled="modelValue === totalPages"
        :title="$t('paginationControl.lastPage')">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" viewBox="-5 -5 26 26"
        class="border cursor-pointer bg-white hover:bg-gray-100 rounded-r-lg">
          <path
            transform="rotate(180 8 8)"
            fill-rule="evenodd"
            d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5
            0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
          <path
            transform="rotate(180 8 8)"
            fill-rule="evenodd"
            d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5
            0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
        </button>
    </div>
    <label for='elementsPerPage' class='flex items-center'>
      <select
        id='elementsPerPage'
        :value='elementsPerPage'
        @input="$emit('update:elementsPerPage', Number($event.target.value))"
        class='border bg-white hover:bg-gray-100 px-2 py-1 rounded-lg'
        :title="$t('paginationControl.showItemsNumber')">
        <option value='10'>10</option>
        <option value='20'>20</option>
        <option value='50'>50</option>
        <option value='100'>100</option>
      </select>
    </label>
  </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';

export default {
  name: 'PaginationControl',
  props: {
    modelValue: Number,
    elementsPerPage: Number,
    totalItems: Number,
  },
  emits: ['update:elementsPerPage', 'update:modelValue'],
  setup() {
    const transitStore = useTransitStore();
    return {
      transitStore,
    };
  },
  methods: {
    navigate(action, pageNumber) {
      let newPage;

      switch (action) {
        case 'goToPage':
          newPage = pageNumber;
          break;
        case 'goToPreviousPage':
          newPage = this.modelValue > 1 ? this.modelValue - 1 : this.modelValue;
          break;
        case 'goToNextPage':
          newPage = this.modelValue < this.totalPages ? this.modelValue + 1 : this.modelValue;
          break;
        case 'goToFirstPage':
          newPage = 1;
          break;
        case 'goToLastPage':
          newPage = this.totalPages;
          break;
        default:
          return;
      }

      this.$emit('update:modelValue', newPage);
      this.$parent.navigatePages();
      this.emitter.emit('back-on-top', true);
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.elementsPerPage);
    },
    pageNumberSequence() {
      const sequence = [];
      const totalDisplayPages = 5;
      const halfDisplayPages = Math.floor(totalDisplayPages / 2);

      let startPage = Math.max(1, this.modelValue - halfDisplayPages);
      let endPage = Math.min(this.totalPages, this.modelValue + halfDisplayPages);

      if (this.totalPages <= totalDisplayPages) {
        startPage = 1;
        endPage = this.totalPages;
      } else if (this.modelValue <= halfDisplayPages) {
        startPage = 1;
        endPage = totalDisplayPages;
      } else if ((this.totalPages - this.modelValue) < halfDisplayPages) {
        startPage = this.totalPages - totalDisplayPages + 1;
        endPage = this.totalPages;
      }

      for (let i = startPage; i <= endPage; i += 1) {
        sequence.push({
          type: i === this.modelValue ? 'strong' : 'a',
          pageNumber: i,
        });
      }

      return sequence;
    },
  },
};
</script>
