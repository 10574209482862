export const AUTH_METHOD_TYPE = {
  1: {
    name: 'AllRead',
    type: 'allread',
  },
  2: {
    name: 'Active Directory',
    type: 'active_directory',
  },
  3: {
    name: 'OAuth2',
    type: 'oauth2',
  },
};

function checkAuthMethodIndexes(authMethodTypesIndexes) {
  if (!authMethodTypesIndexes) return false;

  let validIndexes = true;
  const indexesArray = authMethodTypesIndexes.split(',');
  indexesArray.every((index) => {
    if (Object.hasOwn(AUTH_METHOD_TYPE, index)) return true;
    validIndexes = false;
    return false;
  });

  if (!validIndexes) return false;
  return true;
}

export function getAuthMethodTypes(authMethodTypesIndexes) {
  let indexesArray = [];
  const methodTypeSet = new Set();
  const normalizedAuthTypeIndexes = authMethodTypesIndexes?.replace(' ', '');
  if (checkAuthMethodIndexes(normalizedAuthTypeIndexes)) {
    indexesArray = normalizedAuthTypeIndexes.split(',');
    indexesArray.forEach((index) => { methodTypeSet.add(AUTH_METHOD_TYPE[index]); });
  } else {
    console.warn('No authentication method types defined.');
    Object.keys(AUTH_METHOD_TYPE).forEach((index) => { methodTypeSet.add(AUTH_METHOD_TYPE[index]); });
  }
  return Array.from(methodTypeSet);
}
