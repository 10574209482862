<template>
<div class="absolute top-[-0.6rem] left-2 bg-white px-6 border-3 shadow
border-[#1e3c53] rounded text-gray-700 capitalize">
<b v-if="bold">{{ cardLabelText }}</b>
<p v-else>{{ cardLabelText }}</p>
</div>
</template>

<script>
export default {
  name: 'CardLabel',
  props: ['cardLabelText', 'bold'],
};
</script>
