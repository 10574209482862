import { createApp } from 'vue';
import { createPinia } from 'pinia';
import mitt from 'mitt';
import App from './App.vue';
import router from './router';
import './assets/css/app.css';
import i18n from './locale/i18n';

const emitter = mitt();
const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.use(i18n);
app.use(router);
app.use(createPinia());
app.mount('#app');
