<template>
  <div class="bg-white rounded-md w-full flex justify-center items-center sticky top-0 z-10">
    <ToastComponent ref="globalToast" class="mx-auto"/>
    <div v-for="tabName in tabs" :key="tabName" class="text-lg font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
      <button :class="[commonStyle, currentTab === tabName ? selectedStyle : '']"
          @click="switchTab(tabName)">
          {{ $t(`tabSelector.${tabName}`)}}
      </button>
    </div>
  </div>
  <div class="px-5 text-center rounded-md">
    <div>
      <TransitLiveTab v-if="currentTab == 'live'"/>
      <TransitHistoryTab v-else-if="currentTab == 'history'"/>
      <StatsTab v-else-if="currentTab == 'stats'" />
      <ObjectTab v-else-if="currentTab == 'objects'" />
    </div>
  </div>
</template>

<script>
import TransitHistoryTab from './TransitHistoryTab.vue';
import TransitLiveTab from './TransitLiveTab.vue';
import StatsTab from './StatsTab.vue';
import ObjectTab from './ObjectTab.vue';
import ToastComponent from './generic/ToastComponent.vue';

export default {
  name: 'TabSelector',
  data() {
    return {
      currentTab: 'live',
      commonStyle: 'inline-block px-5 py-3 border-b-4 border-transparent hover:text-sky-400 hover:border-sky-400',
      selectedStyle: 'text-sky-700 border-sky-700 bg-sky-50',
      tabs: [
        'live',
        'history',
        'objects',
        'stats'],
    };
  },
  components: {
    TransitHistoryTab,
    TransitLiveTab,
    StatsTab,
    ObjectTab,
    ToastComponent,
  },
  methods: {
    switchTab(mode) {
      this.currentTab = mode;
    },
  },
  mounted() {
    this.emitter.on('show-toast', (toastInfo) => {
      this.$refs.globalToast.openToast(toastInfo);
    });
  },
  unmounted() {
    this.emitter.off('show-toast');
  },
};
</script>
