export default function transitValidator(data) {
  if (typeof data.count !== 'number') {
    throw new Error("Invalid data: 'count' should be a number.");
  }

  if (!Array.isArray(data.transits)) {
    throw new Error("Invalid data: 'transits' should be an array.");
  }

  data.transits.forEach((transit) => {
    if (typeof transit.id !== 'number') {
      throw new Error("Invalid transit data: 'id' should be a number.");
    }

    if (typeof transit.start_time !== 'string') {
      throw new Error("Invalid transit data: 'start_time' should be a string.");
    }

    if (typeof transit.end_time !== 'string') {
      throw new Error("Invalid transit data: 'end_time' should be a string.");
    }

    if (Array.isArray(transit.meta_objects)) {
      transit.meta_objects.forEach((meta) => {
        if (typeof meta.id !== 'number') {
          throw new Error("Invalid meta_object data: 'id' should be a number.");
        }

        if (Array.isArray(meta.classifications)) {
          meta.classifications.forEach((classification) => {
            if (typeof classification.value !== 'string' || typeof classification.type !== 'string') {
              throw new Error('Invalid classification data.');
            }
          });
        }

        if (meta.reading !== null) {
          if (typeof meta.reading.value !== 'string' || typeof meta.reading.type !== 'string') {
            throw new Error('Invalid reading data.');
          }
        }
      });
    }
  });
}
