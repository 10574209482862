import { defineStore } from 'pinia';
// import statsValidator from '@/validators/statsValidator';
import transitValidator from '@/validators/transitValidator';
import metaObjectValidator from '@/validators/metaObjectValidator';
import { STATISTICAL_TYPE } from '@/utils/lookup';
import { fetchFromAPI, fetchFromUrl, putToAPI } from '@/utils/api';
import { apiUrl } from '@/../TransitViewerConfig';
import i18n from '@/locale/i18n';
import userManagementStore from './UserManagementStore';

const useTransitStore = defineStore('transitStore', {
  state: () => ({
    facility: {},
    location: {},
    transit: {},
    stats: {},
    statsTypes: {},
    metaObject: {},
    metaObjectTypes: {},
    errors: {
      fetchStats: null,
      fetchTransits: null,
      fetchMetaObjects: null,
    },
  }),
  actions: {
    async fetchFacility() {
      try {
        this.facility = await fetchFromAPI('/facility');
      } catch (error) {
        console.error(error);
      }
    },
    async fetchLocation() {
      try {
        this.location = await fetchFromAPI('/locations');
      } catch (error) {
        console.error(error);
      }
    },
    cleanTransits() {
      this.transit = {};
    },
    async fetchTransits({
      sortingType = 'desc',
      sortAttribute = ['end_time'],
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      searchValue = '',
      pageNumber = 0,
      pageSize = 50,
      locations,
      transitTypes,
    } = {}) {
      this.errors = {};
      const queryParams = new URLSearchParams({
        sorting_type: sortingType,
        initial_time: initialTime,
        finish_time: finishTime,
        search_value: searchValue,
        page_number: pageNumber,
        page_size: pageSize,
      });
      if (Array.isArray(sortAttribute)) {
        sortAttribute.forEach((attribute) => {
          queryParams.append('sort_attribute', attribute);
        });
      } else {
        queryParams.append('sort_attribute', sortAttribute);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      if (transitTypes) {
        Object.values(transitTypes).forEach((transitType) => queryParams.append('transit_type', transitType));
      }
      try {
        this.transit = await fetchFromAPI(`/transits?${queryParams.toString()}`);
        if (this.transit.isNoContent) {
          console.warn('No transits returned from this query.');
        } else {
          transitValidator(this.transit);
        }
      } catch (error) {
        console.error(error);
        this.errors.fetchTransits = i18n.global.t('transitStore.errorFetchTransits');
      }
    },
    async fetchTransitsReport({
      sortingType = 'desc',
      sortAttribute = ['end_time'],
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      searchValue = '',
      pageNumber = 0,
      pageSize = 50,
      locations,
      transitTypes,
      reportDict,
    } = {}) {
      this.errors = {};
      const queryParams = new URLSearchParams({
        sorting_type: sortingType,
        initial_time: initialTime,
        finish_time: finishTime,
        search_value: searchValue,
        page_number: pageNumber,
        page_size: pageSize,
        report_type: reportDict.report_type,
      });
      if (Array.isArray(sortAttribute)) {
        sortAttribute.forEach((attribute) => {
          queryParams.append('sort_attribute', attribute);
        });
      } else {
        queryParams.append('sort_attribute', sortAttribute);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      if (transitTypes) {
        Object.values(transitTypes).forEach((transitType) => queryParams.append('transit_type', transitType));
      }
      try {
        const data = await fetchFromAPI(`/transits/report?${queryParams.toString()}`, {
          method: 'GET',
          headers: {
            Accept: reportDict.header,
            Authorization: `Bearer ${userManagementStore().getUserToken()}`,
          },
          responseType: 'blob',
        });
        return data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async fetchImage(source, imageId) {
      try {
        const data = await fetchFromAPI(`/image/${source}/${imageId}`, {
          headers: {
            Accept: 'image/jpeg',
            Authorization: `Bearer ${userManagementStore().getUserToken()}`,
          },
          responseType: 'blob',
        });
        return URL.createObjectURL(data);
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async fetchClientLogo() {
      try {
        const data = await fetchFromUrl(new URL('client_logo.png', apiUrl()).href, {
          headers: {
            Accept: 'image/png',
            Authorization: `Bearer ${userManagementStore().getUserToken()}`,
          },
          responseType: 'blob',
        });
        return URL.createObjectURL(data);
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async fetchStatsTypes() {
      const statTypes = await fetchFromAPI('/stats/types');
      this.statsTypes = {};
      statTypes.types?.forEach((statType) => {
        if (statType in STATISTICAL_TYPE) {
          this.statsTypes[statType] = STATISTICAL_TYPE[statType];
        } else console.error('Type', statType, 'does not exist');
      });
    },
    async fetchStats({
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      statsTypes = [],
      locations,
    } = {}) {
      const queryParams = new URLSearchParams({
        initial_time: initialTime,
        finish_time: finishTime,
      });
      if (Array.isArray(statsTypes)) {
        statsTypes.forEach((attribute) => {
          queryParams.append('statistical_type', attribute);
        });
      } else {
        queryParams.append('statistical_type', statsTypes);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      try {
        const stats = await fetchFromAPI(`/stats?${queryParams.toString()}`);
        // TODO: statsValidator(stats);
        this.stats = stats;
        this.errors.fetchStats = null;
      } catch (error) {
        console.error(error);
        this.errors.fetchStats = i18n.global.t('transitStore.errorFetchStats');
      }
    },
    async fetchMetaObjects({
      sortingType = 'desc',
      sortAttribute = ['end_time'],
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      searchValue = '',
      metaObjectType = [],
      pageNumber = 0,
      pageSize = 50,
      confidenceRange = [0, 1],
      locations = {},
    } = {}) {
      this.errors = {};
      const queryParams = new URLSearchParams({
        sorting_type: sortingType,
        initial_time: initialTime.valueOf(),
        finish_time: finishTime.valueOf(),
        search_value: searchValue,
        page_number: pageNumber,
        page_size: pageSize,
      });
      if (confidenceRange) {
        queryParams.append('minimum_confidence', confidenceRange[0]);
        queryParams.append('maximum_confidence', confidenceRange[1]);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      if (Array.isArray(sortAttribute)) {
        sortAttribute.forEach((attribute) => {
          queryParams.append('sort_attribute', attribute);
        });
      } else {
        queryParams.append('sort_attribute', sortAttribute);
      }
      if (Array.isArray(metaObjectType) && metaObjectType) {
        metaObjectType.forEach((attribute) => {
          queryParams.append('meta_object_type', attribute);
        });
      } else {
        queryParams.append('meta_object_type', metaObjectType);
      }
      try {
        const result = await fetchFromAPI(`/meta_objects?${queryParams.toString()}`);
        if (result.isNoContent) {
          this.metaObject = { meta_objects: [], count: 0 };
        } else {
          this.metaObject = result;
          metaObjectValidator(this.metaObject);
        }
      } catch (error) {
        console.error(error);
        this.errors.fetchMetaObjects = i18n.global.t('transitStore.errorFetchMetaObjects');
      }
    },
    async fetchMetaObjectTypes() {
      try {
        const metaObjectTypes = await fetchFromAPI('/meta_objects/types');
        this.metaObjectTypes = {};
        metaObjectTypes.types?.forEach((metaObjectType) => {
          if (metaObjectType in STATISTICAL_TYPE) {
            this.metaObjectTypes[metaObjectType] = STATISTICAL_TYPE[metaObjectType];
          } else console.error('Type', metaObjectType, 'does not exist');
        });
      } catch (error) {
        console.error(error);
      }
    },
    async putReadingValue({
      readingId = -1,
      newValue = '',
    } = {}) {
      const queryParams = new URLSearchParams({ new_value: newValue });
      return putToAPI(`/reading/${readingId}?${queryParams.toString()}`);
    },
    async putClassificationValue({
      classificationId = -1,
      newValue = '',
    } = {}) {
      const queryParams = new URLSearchParams({ new_value: newValue });
      return putToAPI(`/classification/${classificationId}?${queryParams.toString()}`);
    },
    async handleSignIn(username, passwd, authMethod) {
      try {
        const bodyData = {
          user: username,
          password: passwd,
        };
        let url = '/auth/login';
        if (authMethod === 'active_directory') {
          url = '/auth/login_ldap';
        }
        const signInInfo = await fetchFromAPI(url, {
          method: 'POST',
          body: JSON.stringify(bodyData),
          headers: { 'Content-Type': 'application/json' },
        });
        userManagementStore().setSignInInfo(signInInfo);
        return this.signInfo;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async fetchAccountInfo() {
      let accountInfo = {};
      if (userManagementStore().isSignedIn()) {
        accountInfo = await fetchFromAPI(`/user/${userManagementStore().getUserId()}`);
        userManagementStore().setAccountInfo(accountInfo);
      }
      return accountInfo;
    },
    async handleUserData(accountInfo) {
      let response = '';
      try {
        response = await fetchFromAPI(`/user/${userManagementStore().getUserId()}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userManagementStore().getUserToken()}`,
          },
          body: JSON.stringify(accountInfo),
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
      userManagementStore().setAccountInfo({
        id: response.id,
        email: response.email,
        name: response.name,
        surname: response.surname,
      });
      return response;
    },
  },
});

export default useTransitStore;
