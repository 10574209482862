<template>
  <div class="flex items-center space-x-4 m-4">
    <img
      :src= this.appLogo
      class="h-12 w-auto"
      alt="AllRead Logo"/>
    <div class="absolute top-10 right-20 h-16 w-26" v-if="isSignedIn">
      <UserMenu @accountInfoFromUserMenu="accountInfoReceived" />
    </div>
  </div>
  <TabSelector />
  <transition name="fade">
    <div id="pagetop" class="fixed right-10 bottom-6 bg-gray-100 rounded-full opacity-50 hover:opacity-70"
    v-show="scY > 300" @click="this.toTop(false)">
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
      stroke="#0ea5e9"
      stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
      <path d="M18 15l-6-6-6 6"/>
    </svg>
    </div>
  </transition>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import userManagementStore from '@/stores/UserManagementStore';
import TabSelector from '@/components/TabSelector.vue';
import UserMenu from '@/components/UserMenu.vue';
import { thereIsClientLogo } from '@/../TransitViewerConfig';
import { getIcon } from '@/utils/icons';

export default {
  name: 'TransitView',
  setup() {
    const transitStore = useTransitStore();

    return {
      transitStore,
    };
  },
  data() {
    return {
      scY: 0,
      appLogo: getIcon('applicationLogo'),
    };
  },
  methods: {
    handleScroll() {
      this.scY = window.scrollY;
    },
    toTop(isIstantJump = false) {
      const jumpBehavior = isIstantJump ? 'instant' : 'smooth';
      window.scrollTo({
        top: 0,
        behavior: jumpBehavior,
      });
    },
    accountInfoReceived() {
      this.$router.push({ path: '/user' });
    },
    signedOut() {
      userManagementStore().signOut();
      this.$router.push({ path: '/login' });
    },
  },
  components: {
    TabSelector,
    UserMenu,
  },
  created() {
    document.title = this.$t('transitView.documentTitle');
  },
  async mounted() {
    if (thereIsClientLogo()) {
      const clientLogo = await this.transitStore.fetchClientLogo();
      if (clientLogo) {
        this.appLogo = clientLogo;
      }
    }
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('user-token-expired', this.signedOut);
    this.emitter.on('back-on-top', (isIstantJump) => this.toTop(isIstantJump));
    try {
      this.isLoading = true;
      await this.transitStore.fetchFacility();
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  },
  unmounted() {
    this.emitter.off('click-on-forensic');
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    isSignedIn() {
      return userManagementStore().isSignedIn();
    },
  },
};
</script>
