export default function metaObjectValidator(data) {
  if (typeof data.count !== 'number') {
    throw new Error("Invalid data: 'count' should be a number.");
  }

  if (!Array.isArray(data.meta_objects)) {
    throw new Error("Invalid data: 'meta_objects' should be an array.");
  }

  data.meta_objects.forEach((metaObject) => {
    if (typeof metaObject.id !== 'number') {
      throw new Error("Invalid meta object data: 'id' should be a number.");
    }

    if (typeof metaObject.transit_id !== 'number') {
      throw new Error("Invalid meta object data: 'transit_id' should be a number.");
    }

    if (Array.isArray(metaObject.classifications)) {
      metaObject.classifications.forEach((classification) => {
        if (typeof classification.value !== 'string' || typeof classification.type !== 'string') {
          throw new Error('Invalid classification data.');
        }
      });
    }

    if (metaObject.reading !== null) {
      if (typeof metaObject.reading.value !== 'string' || typeof metaObject.reading.type !== 'string') {
        throw new Error('Invalid reading data.');
      }
    }
  });
}
