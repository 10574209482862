import { createI18n } from 'vue-i18n';
import { viewerLanguage } from '@/../TransitViewerConfig';
import de from './de.json';
import en from './en.json';

const viewerLocale = viewerLanguage() || 'en';
const i18n = createI18n({
  locale: viewerLocale,
  fallbackLocale: 'en',
  legacy: false,
  messages: { en, de },
});

export default i18n;
